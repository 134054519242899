import React from "react";
import Slider from "react-slick";
import {
  cashew_front,
  almond_front,
  almond_side,
  raisin_front,
  pdf1,
} from "../../../assets/images/index";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
// import Pagination from "../../pageProps/shopPage/Pagination";

const NewArrivals = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: < SampleNextArrow / > ,
        prevArrow: < SamplePrevArrow / > ,
        responsive: [{
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
        ],
    };
    return ( <
        div className = "w-full pb-16" >
        <
        Heading heading = "New Arrivals" / >
        <Slider {...settings } >
        <div className = "px-2" >


        <Product _id = "100001"
        img = { cashew_front }
        productName = "Premium Cashews"
        pdf= {pdf1}
        mrp="510.00"
        price="410.00"
        discount= "100.00"
        color = "White"
        badge = { true }
        des = "Maharani Foods premium cashews are great for snacking. The healthy fats and antioxidants in cashews can contribute to cardiovascular health.They contain magnesium and phosphorus, which are important for bone strength."
        ficheTech= {[
            { label: "Calories ", value: "Approximately 576 kcal" },
            { label: "Protein ", value: "21.1 g" },
      
            {
              label: "Saturated Fat",
              value: "3.7 g",
            },
            { label: "Monounsaturated Fat", value: "31.6 g" },
            {
              label: "Polyunsaturated Fat",
              value: "12.4 g",
            },
            { label: "Dietary Fiber", value: "12.5 g" },
            { label: "Sugars", value: " 4.2 g" },
            {
              label: "Vitamin E",
              value: "25.6 mg (approximately 170% of the Daily Value)",
            },
            { label: "Calcium", value: "269 mg" },
            {
              label: "Magnesium",
              value:
                "268 mg",
            },
            { label: "Potassium", value: " 705 mg" },
            {
              label: "Iron",
              value: "3.7 mg",
            },
            {
              label: "Phosphorus",
              value: "484 mg",
            },
          ]}

        />
        </div> 
        <div className = "px-2" >
        <Product _id = "100002"
        img = { raisin_front }
        productName = "Premium Raisins"
        pdf= {pdf1}
        mrp="200.00"
        price= "175.00"
        discount="25.00"
        color = "Light goldenish"
        badge = { true }
        des= "Raisins are dried grapes that are naturally sweet and packed with essential nutrients like potassium, iron, and fiber. They offer a convenient, energy-boosting snack and can enhance both sweet and savory dishes."
        ficheTech={[
      { label: "Calories ", value: "Approximately 299 kcal" },
      { label: "Protein ", value: "3.1 g" },

      {
        label: "Saturated Fat",
        value: "0.1 g",
      },
      { label: "Monounsaturated Fat", value: " 0.1 g" },
      {
        label: "Polyunsaturated Fat",
        value: "0.1 g",
      },
      { label: "Dietary Fiber", value: "3.7 g" },
      { label: "Sugars", value: " 59.2 g" },
      {
        label: "Vitamin C",
        value: "2.3 mg",
      },
      {
        label: "Vitamin K",
        value: "3.6 µg",
      },
      { label: "Calcium", value: "50 mg" },
      {
        label: "Magnesium",
        value:
          "32 mg",
      },
      { label: "Potassium", value: " 749 mg" },
      {
        label: "Iron",
        value: "1.9 mg",
      },
      {
        label: "Copper",
        value: "0.36 mg", 

      },
      {
        label: "Manganese",
        value: "0.3 mg",
      },
    ]}
        />
        </div> <
        div className = "px-2" >
        <Product _id = "100003"
        img = { almond_side }
        productName = "Premium Almonds"
        pdf= {pdf1}
        mrp="455.00"
        price= "355.00"
        discount= "100.00"
        color = "Light brown"
        badge = { true }
        des= "Maharani Foods premium Almonds are great for snacking. Almonds are a good source of vitamin E, magnesium, potassium, and calcium. Vitamin E is a powerful antioxidant, while magnesium supports muscle and nerve function.Almonds are rich in monounsaturated fats, which can help reduce LDL cholesterol levels (the 'bad' cholesterol) and improve overall heart health.The vitamin E in almonds helps reduce oxidative stress and inflammation, which are linked to cardiovascular diseases."
        ficheTech= {[
            { label: "Calories ", value: "Approximately 576 kcal" },
            { label: "Protein ", value: "21.1 g" },
      
            {
              label: "Saturated Fat",
              value: "3.7 g",
            },
            { label: "Monounsaturated Fat", value: "31.6 g" },
            {
              label: "Polyunsaturated Fat",
              value: "12.4 g",
            },
            { label: "Dietary Fiber", value: "12.5 g" },
            { label: "Sugars", value: " 4.2 g" },
            {
              label: "Vitamin E",
              value: "25.6 mg (approximately 170% of the Daily Value)",
            },
            { label: "Calcium", value: "269 mg" },
            {
              label: "Magnesium",
              value:
                "268 mg",
            },
            { label: "Potassium", value: " 705 mg" },
            {
              label: "Iron",
              value: "3.7 mg",
            },
            {
              label: "Phosphorus",
              value: "484 mg",
            },
          ]}
        />
        </div> 
        <div className = "px-2" >
        <Product _id = "100004"
        img = { almond_front }
        productName = "Premium Almonds"
        pdf= {pdf1}
        color= "Brown"
        mrp = "455.00"
        price= "355.00"
        discount= "100.00"
        badge = { false }
        des= "Maharani Foods premium Almonds are great for snacking. Almonds are a good source of vitamin E, magnesium, potassium, and calcium. Vitamin E is a powerful antioxidant, while magnesium supports muscle and nerve function.Almonds are rich in monounsaturated fats, which can help reduce LDL cholesterol levels (the 'bad' cholesterol) and improve overall heart health.The vitamin E in almonds helps reduce oxidative stress and inflammation, which are linked to cardiovascular diseases."
        ficheTech= {[
            { label: "Calories ", value: "Approximately 576 kcal" },
            { label: "Protein ", value: "21.1 g" },
      
            {
              label: "Saturated Fat",
              value: "3.7 g",
            },
            { label: "Monounsaturated Fat", value: "31.6 g" },
            {
              label: "Polyunsaturated Fat",
              value: "12.4 g",
            },
            { label: "Dietary Fiber", value: "12.5 g" },
            { label: "Sugars", value: " 4.2 g" },
            {
              label: "Vitamin E",
              value: "25.6 mg (approximately 170% of the Daily Value)",
            },
            { label: "Calcium", value: "269 mg" },
            {
              label: "Magnesium",
              value:
                "268 mg",
            },
            { label: "Potassium", value: " 705 mg" },
            {
              label: "Iron",
              value: "3.7 mg",
            },
            {
              label: "Phosphorus",
              value: "484 mg",
            },
          ]}
        
        /
        >
        </div> 
        
        <div className = "px-2" >
        <Product _id = "100005"
        img = { cashew_front }
        productName = "Premium Cashews"
        pdf= {pdf1}
        mrp="510.00"
        price="410.00"
        discount= "100.00"
        color = "White"
        badge = { true }
        des = "Maharani Foods premium cashews are great for snacking. The healthy fats and antioxidants in cashews can contribute to cardiovascular health.They contain magnesium and phosphorus, which are important for bone strength."
        ficheTech= {[
            { label: "Calories ", value: "Approximately 576 kcal" },
            { label: "Protein ", value: "21.1 g" },
      
            {
              label: "Saturated Fat",
              value: "3.7 g",
            },
            { label: "Monounsaturated Fat", value: "31.6 g" },
            {
              label: "Polyunsaturated Fat",
              value: "12.4 g",
            },
            { label: "Dietary Fiber", value: "12.5 g" },
            { label: "Sugars", value: " 4.2 g" },
            {
              label: "Vitamin E",
              value: "25.6 mg (approximately 170% of the Daily Value)",
            },
            { label: "Calcium", value: "269 mg" },
            {
              label: "Magnesium",
              value:
                "268 mg",
            },
            { label: "Potassium", value: " 705 mg" },
            {
              label: "Iron",
              value: "3.7 mg",
            },
            {
              label: "Phosphorus",
              value: "484 mg",
            },
          ]}

          />
          </div>
          </Slider > 
          </div>
        
    );
};

export default NewArrivals;